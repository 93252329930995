
import { Heading } from '@chakra-ui/react';
import ItemTable from '../components/inventory/ItemTable';
import { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';

export default function Inventory() {
  const { group } = useContext(AuthContext);
  const { pathname, search } = useLocation();
  
  if (group === 'Production') {
    return <Navigate to={`/?redirect=${pathname}${search}`} />;
  }

  else
  return (
    <>
      <Heading ml={5}>
        Inventory
      </Heading>
      <ItemTable />
    </>
  )
}

