import { ChakraProvider, theme } from '@chakra-ui/react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Loading from './components/main/Loading';
import WithSubnavigation from './components/main/Navbar';
import { AuthProvider } from './context/AuthContext';
import onError from './utility/Error';
import Routes from './utility/Routes';
import { ErrorBoundary } from '@sentry/react';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: onError
  }),
  defaultOptions: {
    queries: {
      staleTime: 600000,
      cacheTime: 900000,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      onError: onError,
    }
  }
})

export const App = () => (
  <QueryClientProvider client={queryClient}>
  <ChakraProvider theme={theme}>
  <ErrorBoundary>
    <AuthProvider>
      <WithSubnavigation />
          <Loading />
          <Routes />
    </AuthProvider>
  </ErrorBoundary>
  </ChakraProvider>
  <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
)
