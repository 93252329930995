import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { queryKeys } from '../../../utility/QueryKeys';

const loadVendors = async() => {
  const response = await API.get("api", "/vendors", {});

  return response;
  }

export function useAllVendors() {
  const fallback: any[] = [];

  const { data = fallback } = useQuery([queryKeys.vendors], loadVendors);

  return data;
}