import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';

import { queryKeys } from '../../../utility/QueryKeys';

const createOrUpdatePo = async(po: any) => {
    const request = await API.put("api", "/orders", {
        body: po,
      });
  
    return request;
    }

  export function useCreateOrUpdatePo(orderId: any) {
    const queryClient = useQueryClient();
    const toast = useToast();
    
    const { mutate } = useMutation([queryKeys.createOrUpdatePurchaseOrder, orderId], (po: any) => createOrUpdatePo(po), {
      onSuccess: () => {
            queryClient.invalidateQueries([queryKeys.purchaseOrders]);
              
            if(orderId === ''){
              toast({
                  title: "Purchase Order Created!",
                  status: "success",
                  isClosable: true,
                  duration: 5000,
              })
            } else {
              toast({
                  title: "Changes Successful!",
                  status: "success",
                  isClosable: true,
                  duration: 5000,
              })
            }
        }
    });
  
    return mutate;
  }