import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';

import { queryKeys } from '../../../utility/QueryKeys';

const updateProduct = async(newValue: any, productId: any, columnId: any, vendorId: any) => {
    if (columnId === 'unitPrice'){
        newValue = parseFloat(newValue);
    }
    const json = {[columnId]: newValue,
    'vendorId': vendorId}
    const request = await API.put("api", `/products/${productId}`, {
        body: json,
    });
    return request;
}

export function useUpdateProduct(productId: any, columnId: any, vendorId: any){
    const queryClient = useQueryClient();

    const { mutate } = useMutation([queryKeys.updateProduct],(newValue: any) => updateProduct(newValue, productId, columnId, vendorId), {
        onSuccess: () => {
            queryClient.invalidateQueries([queryKeys.products]);
        },
    });

    return mutate;
}