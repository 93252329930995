import { Auth } from "aws-amplify";
import { createContext, useEffect, useState } from "react";

const AuthContext = createContext<any>(null)

export const AuthProvider = ({children}:any) => {
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [newUser, setNewUser] = useState(null);
    const [group, setGroup] = useState('');

    useEffect(() => {
        onLoad();
        groupCheck();
      }, []);
      
      async function onLoad() {
        try {
          await Auth.currentSession();
          userHasAuthenticated(true);
        } catch (e) {
          if (e !== "No current user") {
            alert(e);
          }
        }
      
        setIsAuthenticating(false);
      }

      async function groupCheck() {
        try {
          const session = await Auth.currentSession();
          const groups = session.getIdToken().payload['cognito:groups'];
          setGroup(groups[0]);
        } catch (e) {
          if (e !== "No current user") {
            alert(e);
          }
        }
      }
    
    return (
        <AuthContext.Provider 
            value={{
                isAuthenticated,
                userHasAuthenticated,
                isAuthenticating,
                setIsAuthenticating,
                isLoading,
                setIsLoading,
                newUser,
                setNewUser,
                group,
                setGroup
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext