import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { queryKeys } from '../../../utility/QueryKeys';

const loadOrders = async(id: any) => {
  const response = await API.get("api", `/orders/${id}`, {});

  return response;
  }

export function useAllPoByVendor(id: any) {
  const fallback: any[] = [];

  const { data = fallback } = useQuery([queryKeys.purchaseOrders, id], () => loadOrders(id), {
    enabled: !!id
  });

  return data;
}