import { Heading } from "@chakra-ui/react";
import VendorCards from "../components/vendor/VendorCards";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { Navigate, useLocation } from "react-router-dom";

function Vendor() {
  const { group } = useContext(AuthContext);
  const { pathname, search } = useLocation();
  
  if (group !== 'Admin') {
    return <Navigate to={`/?redirect=${pathname}${search}`} />;
  }

  else
  return (
    <>
      <Heading ml={5}>
        Vendors
      </Heading>
      <VendorCards />
    </>
    );
  }
  
export default Vendor