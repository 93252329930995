import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';

import { queryKeys } from '../../../utility/QueryKeys';

const updateProduct = async(newValues: any) => {
    const id = newValues.productId;
    const vendorId = newValues.vendorId;
    const request = await API.put("api", `/products/${vendorId}/${id}`, {
        body: newValues
    });
    return request;
}

export function useUpdateProductList(){
    const queryClient = useQueryClient();

    const { mutate } = useMutation([queryKeys.updateProductList],(newProduct: any) => updateProduct(newProduct), {
        onSuccess: () => {
            queryClient.invalidateQueries([queryKeys.products]);
        },
    });

    return mutate;
}