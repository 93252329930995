export const queryKeys = {
    items: 'items',
    item: 'item',
    createItem: 'createItem',
    deleteItem: 'deleteItem',
    vendors: 'vendors',
    products: 'products',
    updateProduct: 'updateProduct',
    updateProductList: 'updateProductList',
    deleteProduct: 'deleteProduct',
    purchaseOrders: 'purchaseOrders',
    createOrUpdatePurchaseOrder: 'createOrUpdatePurchaseOrder',
    purchaseOrder: 'purchaseOrder',
    purchaseOrderDoc: 'purchaseOrderDoc',
    invoices: 'invoices',
    deliveries: 'deliveries',
    createDelivery: 'createDelivery',
    queue: 'queue',
    takeQueue: 'takeQueue',
};