import { Heading } from "@chakra-ui/react";
import InvoiceCards from "../components/invoice/InvoiceCards";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { Navigate, useLocation } from "react-router-dom";

function Invoice() {
  const { group } = useContext(AuthContext);
  const { pathname, search } = useLocation();
  
  if (group !== 'Admin') {
    return <Navigate to={`/?redirect=${pathname}${search}`} />;
  }

  else
    return (
      <>
        <Heading ml={5}>
        Invoices
        </Heading>
        <InvoiceCards />
      </>
      );
    }
    
  export default Invoice