import { Badge, Button, Card, CardBody, CardFooter, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Heading, Stack, Table, TableContainer, Tbody, Text, Td, Th, Thead, Tr, useDisclosure, Container, SimpleGrid, Input } from "@chakra-ui/react";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import { useState } from "react";
import { useAllInvoices } from "./hooks/useAllInvoices";

function InvoiceCards() {
    const invoiceData = useAllInvoices();
    const [search, setSearch] = useState('');
    
    const handleSearch = (e: any) => {
        setSearch(e.target.value);
      }
    
    const createCards = () => {
        const dataSortedByCreateDate = invoiceData.sort((a: any, b: any) => 
        (a.created > b.created) ? 1 : ((b.created > a.created) ? -1 : 0));

        if(search !== '')
        return (
            dataSortedByCreateDate.filter((invoice: any) => invoice.invoiceId.includes(search)).map((invoice: any) => (
                <CardTemplate key={invoice.invoiceId} invoice={invoice}/>))
        )

        else
        return (
            dataSortedByCreateDate.map((invoice: any) => (
                <CardTemplate  key={invoice.invoiceId} invoice={invoice}/>))
        )
    }

    const CardTemplate = ({invoice}: any) => {
        const lineItemsDrawer = useDisclosure();

        const isMutatingItem = useIsMutating();
        const isFetchingItem = useIsFetching();

        const badgeTheme = (status: string) => {
            if(status === 'matched') return 'green';
            else if(status === 'error') return 'red';
            else return 'gray';
        }

        return (
            <Card
                direction={{ base: 'column', sm: 'row' }}
                overflow='hidden'
                variant='outline'
                maxW={200}
                maxH={190}
                >
                <Stack>
                    <CardBody height={24}>
                    <Stack direction={'row'} spacing={30}>
                        <Heading size='md'>{invoice.invoiceId}</Heading>
                        <Badge colorScheme={badgeTheme(invoice.status)}>{invoice.status}</Badge>
                    </Stack>
                    <Text py='2' lineHeight={6}>
                        Total Quantity: {invoice.totalQuantity}
                        <br />
                        Total Price: {invoice.totalPrice}
                        <br />
                    </Text>
                    </CardBody>
                    <CardFooter>
                    <Button variant='solid' colorScheme='blue' onClick={lineItemsDrawer.onOpen} isDisabled={((isFetchingItem || isMutatingItem) ? true : false) || (invoice.lineItems === null)}>
                        View Line Items
                    </Button>
                    <Drawer
                        isOpen={lineItemsDrawer.isOpen}
                        placement='right'
                        onClose={lineItemsDrawer.onClose}
                        size='full'
                    >
                        <DrawerOverlay />
                        <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>{invoice.invoiceId} Line Items</DrawerHeader>

                        <DrawerBody>
                        <TableContainer>
                            <Table variant='simple'>
                            <Thead>
                                <Tr key={"header"}>
                                <Th key={"productId"}>Product ID</Th>
                                <Th key={"description"}>Description</Th>
                                <Th key={"quantity"} isNumeric>Quantity</Th>
                                <Th key={"unitPrice"} isNumeric>Unit Price</Th>
                                <Th key={"orderQuantity"} isNumeric>Order Quantity</Th>
                                <Th key={"extendedPrice"} isNumeric>Extended Price</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                            {invoice.lineItems?.map((lineItem: any) => (
                                <Tr key={lineItem.productId}>
                                <Td key={lineItem.productId}>{lineItem.productId}</Td>
                                <Td key={lineItem.productId+lineItem.description}>{lineItem.description}</Td>
                                <Td key={lineItem.productId+lineItem.quantity}>{lineItem.quantity}</Td>
                                <Td key={lineItem.productId+lineItem.unitPrice}>{lineItem.unitPrice}</Td>
                                <Td key={lineItem.productId+lineItem.orderQuantity}>{lineItem.orderQuantity}</Td>
                                <Td key={lineItem.productId+lineItem.extendedPrice}>{lineItem.extendedPrice}</Td>
                                </Tr>
                                ))
                            }
                            </Tbody>
                            </Table>
                        </TableContainer>
                        </DrawerBody>

                        <DrawerFooter>
                            <Button colorScheme='blue' onClick={lineItemsDrawer.onClose}>Ok</Button>
                        </DrawerFooter>
                        </DrawerContent>
                    </Drawer>
                    </CardFooter>
                </Stack>
            </Card>)
    }
    

    return (
        <>
        <Input
        type="search"
        name='invoiceSearch'
        value={search}
        onChange={handleSearch}
        placeholder='Search ID'
        ml={4}
        maxW={200}
        />
        <Container maxW={'100%'} mt={4} maxH={'100%'}>
            <SimpleGrid spacingY={4} columns={5}>
                {createCards()}
            </SimpleGrid>
        </Container>
        </>
    )
}

export default InvoiceCards