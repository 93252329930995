import { Heading } from "@chakra-ui/react";
import DeliveryCards from "../components/delivery/DeliveryCards";
import { Navigate, useLocation } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

function Delivery() {
  const { group } = useContext(AuthContext);
  const { pathname, search } = useLocation();
  
  if (group === 'Production') {
    return <Navigate to={`/?redirect=${pathname}${search}`} />;
  }

  else
    return (
      <>
        <Heading ml={5}>
        Deliveries
        </Heading>
        <DeliveryCards />
      </>
      );
    }
    
  export default Delivery