import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API } from "aws-amplify";
import { queryKeys } from "../../../utility/QueryKeys";

const deleteProducts = async(id: any, vendorId: any) => {
    const request = await API.del("api", `/products/${id}`, {
        body: {
            vendorId: vendorId,
        }
    });
    return request;
}

export function useDeleteProducts (vendorId: any) {
    const queryClient = useQueryClient();

    const { mutate } = useMutation([queryKeys.deleteProduct], (id: any) => deleteProducts(id, vendorId), {
        onSuccess: () => {
            queryClient.invalidateQueries([queryKeys.products]);
        },
    });

    return mutate;
}