import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Link, Stack, useColorModeValue } from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { useContext, useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';

import AuthContext from '../../context/AuthContext';
import onError from '../../utility/Error';

  
  export default function SimpleCard() {
    const {userHasAuthenticated} = useContext(AuthContext)
    const {isLoading, setIsLoading} = useContext(AuthContext)
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })
    
    const {email, password} = formData
    
    const onChange = (e: any) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }
    
    const onSubmit = async(e: any) => {
        e.preventDefault()

        setIsLoading(true);

        try {
            await Auth.signIn(email, password);
            userHasAuthenticated(true);
            setIsLoading(false);
          } catch (e: any) {
            onError(e);
            setIsLoading(false);
          }
        }

    const validateForm = () => {
        return email.length > 0 && password.length > 0;
      }

    return (
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}>
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>Sign in to your account</Heading>
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack spacing={4}>
                <form onSubmit={onSubmit}>
                <FormControl id="email" isRequired>
                    <FormLabel>Email Address</FormLabel>
                    <Input 
                    type="email"
                    name='email' 
                    value={email} 
                    onChange={onChange} 
                    placeholder='Enter your email' 
                    autoFocus />
                </FormControl>
                <FormControl id="password" isRequired>
                    <FormLabel>Password</FormLabel>
                    <Input 
                    type="password"
                    name='password' 
                    value={password} 
                    onChange={onChange} 
                    placeholder='Enter password' />
                </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}>
                  {/*<Checkbox>Remember me</Checkbox>*/}
                  <Link as={ReactLink} to="/login/reset" color={'blue.400'} mt={5}>Forgot password?</Link>
                </Stack>
                <Button
                  isLoading={isLoading}
                  loadingText='Submitting'
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}
                  isDisabled={!validateForm() || isLoading}
                  type='submit'>
                  Sign in
                </Button>
              </Stack>
              </form>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    );
  }