import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  chakra,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel, Input, Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure
} from '@chakra-ui/react';
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import {
  ColumnDef,
  createColumnHelper,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable
} from '@tanstack/react-table';
import { HTMLProps, useEffect, useRef, useState } from 'react';

import { useAllItems } from './hooks/useAllItems';
import { useCreateItem } from './hooks/useCreateItem';
import { useDeleteItems } from './hooks/useDeleteItems';
import { useUpdateItem } from './hooks/useUpdateItem';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

export type Item = {
  itemId: string;
  info: string;
  stock: number;
  location: string;
}

const defaultColumn: Partial<ColumnDef<Item>> = {
  cell: function Cell ({ getValue, column, row:{getValue: getRowValue}}) {
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
      setValue(initialValue)
    }, [initialValue])

    const id = getRowValue('itemId');
    const columnId = column.id;

    const handleUpdate = useUpdateItem(id, columnId);

    if (column.id === 'itemId')
    
      return (
        <Input
            readOnly
            flex={'auto'}
            value={value as string}
            onChange={e => setValue(e.target.value)}
        />
        )
    else
    
      return (
      <Input
          onBlur={e => handleUpdate(e.target.value)}
          flex={'auto'}
          value={value as string}
          onChange={e => setValue(e.target.value)}
      />
      )
  }
}

function ItemTable() {
  const data = useAllItems();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState({});
  const columnHelper = createColumnHelper<Item>();
  const [globalFilter, setGlobalFilter] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstField= useRef(null);
  const [formData, setFormData] = useState({
    itemId: '',
    info: '',
    stock: 0,
    location: ''
  });

  const {itemId, info, stock, location} = formData;

  const onSubmit = useCreateItem();

  const onChange = (e: any) => {
      setFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
      }))
  }

  const handleSubmit = (e: any) => {
      e.preventDefault();
      onSubmit(formData);
  }

  const validateForm = () => {
      return info.length > 0
      && stock.toString().length > 0
      && location.length > 0;
    }

  const columns = [
    {
      id: 'select',
      header: ({ table }: any) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }: any) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    },
    columnHelper.accessor("itemId", {
      header: "Item ID",
      footer: (info: { column: { id: any; }; }) => info.column.id
    }),
    columnHelper.accessor("info", {
      header: "Info",
      footer: (info: { column: { id: any; }; }) => info.column.id
    }),
    columnHelper.accessor("stock", {
      header: "Stock",
      meta: {
        isNumeric: true
      },
      footer: (info: { column: { id: any; }; }) => info.column.id
    }),
    columnHelper.accessor("location", {
      header: "Location",
      footer: (info: { column: { id: any; }; }) => info.column.id
    })
  ];

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    defaultColumn,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
    rowSelection,
    sorting,
    globalFilter,
    },
    debugTable: true,
  });

  const selectedRowIds = () => {
   const values = table.getSelectedRowModel().rows.map((item) => item.getValue("itemId"));
  
    return values;
  }

  const handleDelete = useDeleteItems();

  const deleteEach = () => {
   return selectedRowIds().forEach((id) => handleDelete(id));
  }

  const isMutatingItem = useIsMutating();
  const isFetchingItem = useIsFetching();

  const columnWidth = (columnId: any) => {
    if(columnId === 'info')
      return 400;
    else if(columnId === 'stock')
      return 50;
    else if(columnId === 'itemId')
      return 150;
    else
      return 100;
  }

  return (
    <>
    <Container maxW={'100%'}>
      <Box>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Drawer
            isOpen={isOpen} 
            onClose={onClose} 
            placement='right'
            initialFocusRef={firstField}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Create an item</DrawerHeader>
                <DrawerBody>
                <form onSubmit={handleSubmit} id={"createForm"}>
                <Stack spacing={4}>
                    <FormControl id="itemId">
                        <FormLabel>Item ID</FormLabel>
                        <Input 
                        type="text"
                        name='itemId' 
                        value={itemId}
                        variant="filled"
                        onChange={onChange} 
                        placeholder='Item ID' 
                        isReadOnly/>
                    </FormControl>
                    <FormControl id="info" isRequired>
                        <FormLabel>Info</FormLabel>
                        <Input
                        ref={firstField}
                        type="text"
                        name='info' 
                        value={info} 
                        onChange={onChange} 
                        placeholder='Enter info' />
                    </FormControl>
                    <FormControl id="stock" isRequired>
                        <FormLabel>Stock</FormLabel>
                        <Input 
                        type="number"
                        name='stock' 
                        value={stock} 
                        onChange={onChange} 
                        placeholder='Enter stock' />
                    </FormControl>
                    <FormControl id="location" isRequired>
                        <FormLabel>Location</FormLabel>
                        <Input 
                        type="text"
                        name='location' 
                        value={location} 
                        onChange={onChange} 
                        placeholder='Enter location' />
                    </FormControl>
                </Stack>
                </form>
                </DrawerBody>
                <DrawerFooter>
                    <Button
                    form='createForm'
                    type='submit'
                    isDisabled={!validateForm() || (isFetchingItem || isMutatingItem) ? true : false}>
                    Save
                    </Button>
                </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </Flex>
      </Box>
        <TableContainer maxWidth={1000} maxH={375} overflowY={'scroll'} margin={'auto'}>
          <Table variant='striped' colorScheme='teal'>
          <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                  const meta: any = header.column.columnDef.meta;
                  return (
                      <Th
                      colSpan={header.colSpan}
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      isNumeric={meta?.isNumeric}
                      >
                      {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                      )}
                      <chakra.span pl={'4'}>
                          {header.column.getIsSorted() ? (
                          header.column.getIsSorted() === "desc" ? (
                              <TriangleDownIcon aria-label="sorted descending" />
                          ) : (
                              <TriangleUpIcon aria-label="sorted ascending" />
                          )
                          ) : null}
                      </chakra.span>
                      </Th>
                  );
                  })}
              </Tr>
              ))}
          </Thead>
          <Tbody>
              {table.getRowModel().rows.map(row => {
              return(
              <Tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                  const meta: any = cell.column.columnDef.meta;
                  return (
                      <Td
                      minW={columnWidth(cell.column.id)}
                      key={cell.id}
                      isNumeric={meta?.isNumeric}>
                      {flexRender(
                          cell.column.columnDef.cell, 
                          cell.getContext()
                          )}
                      </Td>
                      )
                  })}
              </Tr>
              )
          })}
          </Tbody>
          </Table>
        </TableContainer>
  </Container>
  <Container maxH={10} maxWidth={'100%'} mt={6}>
    <Box boxShadow={'md'} position={'fixed'} ml={14}>
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={value => setGlobalFilter(String(value))}
            placeholder="Search all columns..."
          />
    </Box>
    <Box maxWidth={1000}>
      <Stack direction={'row'} spacing={6} justifyContent={'right'}>
      <Button
        name='createItem'
        onClick={onOpen}
        colorScheme="blue"
        mr={3}
        >
        Create Item
      </Button>
      <Button
        onClick={deleteEach}
        variant="ghost"
        colorScheme="red"
        >
        Delete Item(s)
      </Button>
      </Stack>
    </Box>
  </Container>
  </>
    );
}

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!)

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate, rest.checked])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + ' cursor-pointer'}
      {...rest}
    />
  )
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [debounce, onChange, value])

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}

export default ItemTable