import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';

import { queryKeys } from '../../../utility/QueryKeys';

const createDelivery = async(delivery: any) => {
    const request = await API.post("api", "/deliveries", {
        body: delivery,
      });
  
    return request;
    }

  export function useCreateDelivery() {
    const queryClient = useQueryClient();
    
    const { mutate } = useMutation([queryKeys.createDelivery], (delivery: any) => createDelivery(delivery), {
        onSuccess: () => {
            queryClient.invalidateQueries([queryKeys.deliveries]);
        }
    });
  
    return mutate;
  }