import { InfoIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { API, Auth } from 'aws-amplify';
import { useContext, useEffect, useState } from 'react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';

import AuthContext from '../../context/AuthContext';
import onError from '../../utility/Error';

  
  export default function SignupAndVerifyCards() {
    const [showPassword, setShowPassword] = useState(false)
    const {isLoading, setIsLoading} = useContext(AuthContext)
    const {userHasAuthenticated} = useContext(AuthContext)
    const {newUser, setNewUser} = useContext(AuthContext)
    const [secret, setSecret] = useState('');
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        orgCode: '',
        password: '',
        password2: '',
        confirmationCode: '[]'
    })

    const {firstName, lastName, email, orgCode, password, password2, confirmationCode} = formData

    const nav = useNavigate();

    const onChange = (e: any) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    useEffect(() => {
      getOrgCode();
    }, []);

    const getOrgCode = async() => {
      await API.get("api", "/orgCode", {}
      ).then((response) => {
        setSecret(response);
      }).catch((error) => {
        console.log(error);
      }
    );
    }
    
    const onSubmit = async(e: any) => {
      e.preventDefault();
      setIsLoading(true);
      try {
        const newUser = await Auth.signUp({
          username: email,
          password: password,
        });
        setIsLoading(false);
        setNewUser(newUser);
      } catch (e: any) {
          if(e.name === "UsernameExistsException"){
            const newUser = {
              username: email,
              password: password,
          }
            setNewUser(newUser);
            setIsLoading(false);
            Auth.resendSignUp(email);
          }
        onError(e);
        setIsLoading(false);
      }
        }

    const onConfirmationSubmit = async(e: any) => {
      e.preventDefault();
      setIsLoading(true);
      try {
        await Auth.confirmSignUp(email, confirmationCode);
        await Auth.signIn(email, password);
        let session = await Auth.currentSession();
        //add the new user to 'Production' group as default
        await API.post("api", "/defaultUserGroup", {
          body: {                                   
            username: session.getIdToken().payload['cognito:username'],
          },
        });
        //we have to reset the userSession data to get the new group
        //so that home loads with correct permissions
        await Auth.signOut();
        await Auth.signIn(email, password);
        userHasAuthenticated(true);
        nav("/");
        setIsLoading(false);
      } catch (e) {
        onError(e);
        setIsLoading(false);
      }
    }

    const validateForm = () => {
        return (
            firstName.length > 0 &&
            lastName.length > 0 &&    
            email.length > 0 &&
            orgCode === secret &&
            password.length > 0 &&
            password === password2
        );
      }

    const validateConfirmationForm = () => {
      return confirmationCode.length === 6;
    }

    function VerifyCard() {
      return (
        <Flex
          minH={'100vh'}
          align={'center'}
          justify={'center'}
          bg={useColorModeValue('gray.50', 'gray.800')}>
          <Stack
            spacing={4}
            w={'full'}
            maxW={'sm'}
            bg={useColorModeValue('white', 'gray.700')}
            rounded={'xl'}
            boxShadow={'lg'}
            p={6}
            my={10}>
            <Center>
              <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
                Verify your Email
              </Heading>
            </Center>
            <Center
              fontSize={{ base: 'sm', sm: 'md' }}
              color={useColorModeValue('gray.800', 'gray.400')}>
              We have sent code to your email
            </Center>
            <Center
              fontSize={{ base: 'sm', sm: 'md' }}
              fontWeight="bold"
              color={useColorModeValue('gray.800', 'gray.400')}>
              {email}
            </Center>
            <form onSubmit={onConfirmationSubmit}>
              <FormControl id='confirmationCode' isRequired>
                <Center>
                  <HStack>
                    <Input
                      type="number"
                      name='confirmationCode' 
                      value={confirmationCode} 
                      onChange={onChange}  
                      autoFocus
                      textAlign='center' />
                  </HStack>
                </Center>
                <Stack spacing={6} pt={4}>
                  <Button
                    isLoading={isLoading}
                    loadingText="Verifying"
                    bg={'blue.400'}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}
                    isDisabled={!validateConfirmationForm() || isLoading}
                    type='submit'>
                    Verify
                  </Button>
                </Stack>
              </FormControl>
            </form>
          </Stack>
        </Flex>
      );
    }

    function SignupCard() {
      return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
              <Stack align={'center'}>
                <Heading fontSize={'4xl'} textAlign={'center'}>
                  Sign up
                </Heading>
              </Stack>
              <Box
                rounded={'lg'}
                bg={useColorModeValue('white', 'gray.700')}
                boxShadow={'lg'}
                p={8}>
                <Stack spacing={4}>
                  <form onSubmit={onSubmit}>
                  <HStack>
                    <Box>
                      <FormControl id="firstName" isRequired>
                        <FormLabel>First Name</FormLabel>
                        <Input 
                        type="text"
                        name='firstName' 
                        value={firstName} 
                        onChange={onChange} 
                        placeholder='Enter your first name' 
                        autoFocus />
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl id="lastName" isRequired>
                        <FormLabel>Last Name</FormLabel>
                        <Input 
                        type="text"
                        name='lastName' 
                        value={lastName} 
                        onChange={onChange} 
                        placeholder='Enter your last name' />
                      </FormControl>
                    </Box>
                  </HStack>
                  <FormControl id="email" isRequired>
                    <FormLabel>Email address</FormLabel>
                    <Input 
                    type="email"
                    name='email' 
                    value={email} 
                    onChange={onChange} 
                    placeholder='Enter your email'
                    />
                  </FormControl>
                  <FormControl id="orgCode" isRequired>
                    <FormLabel>Organization Code</FormLabel>
                    <Input 
                    type="text"
                    name='orgCode' 
                    value={orgCode} 
                    onChange={onChange} 
                    placeholder='Enter organization code'
                    />
                  </FormControl>
                  <FormControl id="password" isRequired>
                    <FormLabel>
                      Password
                      <Tooltip label={"Password must be at least 8 characters long and contain at least 1 number, 1 special character, 1 uppercase letter, and 1 lowercase letter."} aria-label='A tooltip'>
                        <InfoIcon ml={2} />
                      </Tooltip>
                    </FormLabel>
                    <InputGroup>
                      <Input 
                      type={showPassword ? 'text' : 'password'}
                      name='password' 
                      value={password} 
                      onChange={onChange} 
                      placeholder='Enter password'/>
                      <InputRightElement h={'full'}>
                        <Button
                          variant={'ghost'}
                          onClick={() =>
                            setShowPassword((showPassword) => !showPassword)
                          }>
                          {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  <FormControl id="password2" isRequired>
                      <Input
                      mt={2} 
                      type={showPassword ? 'text' : 'password'}
                      name='password2' 
                      value={password2} 
                      onChange={onChange} 
                      placeholder='Confirm password' />
                  </FormControl>
                  <Stack spacing={10} pt={2}>
                    <Button
                      isLoading={isLoading}
                      loadingText="Submitting"
                      size="lg"
                      bg={'blue.400'}
                      color={'white'}
                      _hover={{
                        bg: 'blue.500',
                      }}
                      isDisabled={!validateForm() || isLoading}
                      type='submit'>
                      Sign up
                    </Button>
                  </Stack>
                  <Stack pt={6}>
                    <Text align={'center'}>
                      Already a user? <Link as={ReactLink} to='/login' color={'blue.400'}>Login</Link>
                    </Text>
                  </Stack>
                  </form>
                </Stack>
              </Box>
            </Stack>
          </Flex>
      );
    }
  
    return (
      <>
        {newUser === null ? SignupCard() : VerifyCard()}
      </>
    );
  }