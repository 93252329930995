import SimpleCard from '../components/login+signup/LoginCard';

function Login() {
    return(
        <>
           <SimpleCard /> 
        </>
    );
    /*{const [formData, setFormData] = useState({
        email: '',
        password: ''
    })
    
    const {email, password} = formData
    
    const onChange = (e: any) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }
    
    const onSubmit = (e: any) => {
        e.preventDefault()
    }

    const validateForm = () => {
        return email.length > 0 && password.length > 0;
      }

    return(
        <>
            <section className="heading">
                <h1>
                    <FaUser /> Login
                </h1>
                <p> Please enter your email and password</p>
            </section>
            <section className="form">
                <form onSubmit={onSubmit}>
                    <div className="form-group">
                        <input type="email"
                            className="form-control"
                            id='email'
                            name='email' 
                            value={email} 
                            onChange={onChange} 
                            placeholder='Enter your email' 
                            required
                            autoFocus
                        />
                    </div>
                    <div className="form-group">
                        <input type="password"
                            className="form-control"
                            id='password'
                            name='password' 
                            value={password} 
                            onChange={onChange} 
                            placeholder='Enter password'
                            required 
                        />
                    </div>
                    <div className="form-group">
                        <button className='btn btn-block' isDisabled={!validateForm()}>
                            Submit
                        </button>
                    </div>
                </form>
            </section>
        </>			
    )}*/
}

export default Login