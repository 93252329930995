import SignupAndVerifyCards from "../components/login+signup/SignupAndVerifyCards"

function Register() {
  return (
    <>
      <SignupAndVerifyCards />
    </>
    );
  }
  
export default Register