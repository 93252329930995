import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';

import { queryKeys } from '../../../utility/QueryKeys';

const createPurchaseOrderDoc = async(po: any, vendor: any) => {
    const response = await API.post("api", `/podoc/${vendor}/${po.orderId}`, {
        body: po,
        responseType: 'arraybuffer',
        responseEncoding: 'binary',
    });
    return response;
    }

  export function useCreatePurchaseOrderDoc(po: any, vendor: any, injectPo: boolean) {
    const fallback: any[] = [];
    
    const { data = fallback } = useQuery([queryKeys.purchaseOrderDoc, po, injectPo], () => createPurchaseOrderDoc(po, vendor),{
      enabled: !!po && !!injectPo === true,
      onSuccess: (data) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `po_${po.orderId}.pdf`;
        link.click();
      }
    });
  
    return data;
  }