import { Button, Card, CardBody, CardFooter, Container, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, Heading, Input, Select, SimpleGrid, Stack, Text, useDisclosure} from '@chakra-ui/react';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useAllDeliveries } from './hooks/useAllDeliveries';
import { useCreateDelivery } from './hooks/useCreateDelivery';

enum DeliveryStatus {
    'ok' = 'OK',
    'error' = 'Error',
  }

function DeliveryCards() {
    const deliveryData = useAllDeliveries();
    const createDeliveryDrawer = useDisclosure();
    const firstField= useRef(null);
    const date = new Date().toISOString().slice(0, 10);
    const [search, setSearch] = useState('');
    const [deliveryFormData, setDeliveryFormData] = useState({
        deliveryId: '',
        invoiceId: '',
        deliveryDate: date,
        deliveryStatus: '',
        deliveryNotes: ''
      });
    
    const {deliveryId, invoiceId, deliveryDate, deliveryStatus, deliveryNotes} = deliveryFormData;

    useEffect(() => {
        if(!createDeliveryDrawer.isOpen) {
          setDeliveryFormData({
            deliveryId: '',
            invoiceId: '',
            deliveryDate: date,
            deliveryStatus: '',
            deliveryNotes: ''
          });
        }
      }, [createDeliveryDrawer.isOpen, date]);

    const handleSearch = (e: any) => {
    setSearch(e.target.value);
    }

    const onSubmit = useCreateDelivery();

    const onChange = (e: any) => {
        setDeliveryFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const validateForm = () => {
        return deliveryDate.length > 0
        && deliveryStatus.length > 0
        && deliveryNotes.length > 0
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        onSubmit(deliveryFormData);
        createDeliveryDrawer.onClose();
    }

    const isMutatingItem = useIsMutating();
    const isFetchingItem = useIsFetching();

    const createCards = () => {
        const dataSortedByDeliveryDate = deliveryData.sort((a: any, b: any) => 
            (a.deliveryDate > b.deliveryDate) ? 1 : ((b.deliveryDate > a.deliveryDate) ? -1 : 0));

        if(search !== '')
        return (
            dataSortedByDeliveryDate.filter((delivery: any) => delivery.deliveryId.includes(search)).map((delivery: any) => (
                <CardTemplate key={delivery.deliveryId} delivery={delivery}/>))
        )
        
        else
        return (
            dataSortedByDeliveryDate.map((delivery: any) => (
                <CardTemplate  key={delivery.deliveryId} delivery={delivery}/>))
        )
    }

    const CardTemplate = ({delivery}: any) => {

        return (
            <Card
                direction={{ base: 'column', sm: 'row' }}
                overflow='hidden'
                variant='outline'
                maxW={300}
                maxH={190}
                >
                <Stack>
                    <CardBody height={24}>
                    <Heading size='md'>{delivery.deliveryId}</Heading>
                    <Text py='2' lineHeight={6}>
                        Delivery Date: {delivery.deliveryDate}
                        <br />
                        Notes: {delivery.deliveryNotes}
                        <br />
                    </Text>
                    </CardBody>
                    <CardFooter>
                        <Text py='2' lineHeight={6}>
                            Delivery Status: {delivery.deliveryStatus}
                            <br />
                            Invoice Id: {delivery.invoiceId}
                        </Text>
                    </CardFooter>
                </Stack>
            </Card>)
    }
    

    return (
        <>
        <Container maxW={'100%'} mt={4} maxH={'100%'}>
            <Stack direction={'row'}>
                <Button mb={'4'} variant='solid' colorScheme={'blue'} onClick={createDeliveryDrawer.onOpen}>Create Delivery</Button>
                <Input
                type="search"
                name='deliverySearch'
                value={search}
                onChange={handleSearch}
                placeholder='Search ID'
                ml={4}
                mb={4}
                maxW={200}
                />
            </Stack>
            <SimpleGrid spacingY={4} columns={3}>
                {createCards()}
            </SimpleGrid>
        </Container>
        <Drawer
        size={'lg'}
        isOpen={createDeliveryDrawer.isOpen}
        placement='right'
        onClose={createDeliveryDrawer.onClose}
        initialFocusRef={firstField}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        > 
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>Create PO</DrawerHeader>
          <DrawerBody>
          <form onSubmit={handleSubmit} id={"createPoForm"}>
            <Stack spacing={4}>
                <FormControl id="deliveryId">
                    <FormLabel>Delivery ID</FormLabel>
                    <Input 
                    type="text"
                    name='deliveryId' 
                    value={deliveryId}
                    variant="filled"
                    placeholder='Auto-Generated' 
                    isReadOnly/>
                </FormControl>
                <FormControl id="invoiceId">
                    <FormLabel>Invoice ID</FormLabel>
                    <Input 
                    type="text"
                    ref={firstField}
                    name='invoiceId' 
                    value={invoiceId}
                    onChange={onChange}
                    placeholder='Invoice ID'/>
                </FormControl>
                <FormControl id="deliveryDate" isRequired>
                <FormLabel>Delivery Date</FormLabel>
                  <Input
                    type="date"
                    name='deliveryDate'
                    value={deliveryDate}
                    onChange={onChange}
                    placeholder='Select a date'
                    />
                </FormControl>
                <FormControl id="deliveryStatus" isRequired>
                <FormLabel>Delivery Status</FormLabel>
                    <Select 
                        placeholder='Select a status'
                        typeof='string'
                        name='deliveryStatus'
                        value={deliveryStatus}
                        onChange={onChange}>
                    <option value={DeliveryStatus.ok}>OK</option>
                    <option value={DeliveryStatus.error}>Error</option>
                    </Select>
                </FormControl>
                <FormControl id="deliveryNotes" isRequired>
                    <FormLabel>Delivery Notes</FormLabel>
                    <Input 
                    type="text"
                    name='deliveryNotes'
                    value={deliveryNotes} 
                    onChange={onChange}
                    placeholder='Enter notes' />
                </FormControl>
            </Stack>
          </form>
          </DrawerBody>
          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={createDeliveryDrawer.onClose} isDisabled={isFetchingItem || isMutatingItem ? true : false}>
              Cancel
            </Button>
            <Button 
              colorScheme='blue' 
              type="submit"
              form="createPoForm"
              isDisabled={!validateForm() || (isFetchingItem || isMutatingItem) ? true : false}
            >
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      </>
    )
}

export default DeliveryCards