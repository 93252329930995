import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { queryKeys } from '../../../utility/QueryKeys';

const loadInvoices = async() => {
  const response = await API.get("api", "/invoices", {});

  return response;
  }

export function useAllInvoices() {
  const fallback: any[] = [];

  const { data = fallback } = useQuery([queryKeys.invoices], loadInvoices);

  return data;
}