import { Button, Card, CardBody, Container, FormControl, FormHelperText, FormLabel, Input, Link } from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import onError from '../utility/Error';

export default function ResetPassword() {
  const [fields, setFieldData] = useState({
    code: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [codeSent, setCodeSent] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);

  const onChange = (e: any) => {
    setFieldData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
    }))
}

  function validateCodeForm() {
    return fields.email.length > 0;
  }

  function validateResetForm() {
    return (
      fields.code.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  async function handleSendCodeClick(event: any) {
    event.preventDefault();

    setIsSendingCode(true);

    try {
      await Auth.forgotPassword(fields.email);
      setCodeSent(true);
    } catch (error) {
      onError(error);
      setIsSendingCode(false);
    }
  }

  async function handleConfirmClick(event: any) {
    event.preventDefault();

    setIsConfirming(true);

    try {
      await Auth.forgotPasswordSubmit(
        fields.email,
        fields.code,
        fields.password
      );
      setConfirmed(true);
    } catch (error) {
      onError(error);
      setIsConfirming(false);
    }
  }

  function renderRequestCodeForm() {
    return (
      <Container mt={40}>
        <Card maxW={'md'}>
            <CardBody>
              <form onSubmit={handleSendCodeClick}>
                  <FormControl  id="email" isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input
                      autoFocus
                      type="email"
                      name="email"
                      value={fields.email}
                      onChange={onChange}
                  />
                  </FormControl>
                  <Button
                  type="submit"
                  isLoading={isSendingCode}
                  isDisabled={!validateCodeForm()}
                  >
                  Send Confirmation
                  </Button>
              </form>
            </CardBody>
        </Card>
      </Container>
    );
  }

  function renderConfirmationForm() {
    return (
      <form onSubmit={handleConfirmClick}>
        <FormControl id="code" isRequired>
          <FormLabel>Confirmation Code</FormLabel>
          <Input
            autoFocus
            type="number"
            name='code'
            value={fields.code}
            onChange={onChange}
          />
          <FormHelperText>
            Please check your email ({fields.email}) for the confirmation code.
          </FormHelperText>
        </FormControl>
        <hr />
        <FormControl id="password" isRequired>
          <FormLabel>New Password</FormLabel>
          <Input
            type="password"
            name='password'
            value={fields.password}
            onChange={onChange}
          />
        </FormControl>
        <FormControl id="confirmPassword" isRequired>
          <FormLabel>Confirm Password</FormLabel>
          <Input
            type="password"
            name='confirmPassword'
            value={fields.confirmPassword}
            onChange={onChange}
          />
        </FormControl>
        <Button
          type="submit"
          isLoading={isConfirming}
          isDisabled={!validateResetForm()}
        >
          Confirm
        </Button>
      </form>
    );
  }

  function renderSuccessMessage() {
    return (
      <div>
        <p>Your password has been reset.</p>
        <p>
          <Link as={ReactLink} to="/login">
            Click here to login with your new credentials.
          </Link>
        </p>
      </div>
    );
  }

  return (
    <div className="ResetPassword">
      {!codeSent
        ? renderRequestCodeForm()
        : !confirmed
        ? renderConfirmationForm()
        : renderSuccessMessage()}
    </div>
  );
}