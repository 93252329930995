import { Route, Routes } from 'react-router-dom';
import AuthenticatedRoute from '../components/login+signup/AuthenticatedRoute';
import UnauthenticatedRoute from '../components/login+signup/UnauthenticatedRoute';

import Delivery from '../pages/Delivery';
import Home from '../pages/Home';
import Inventory from '../pages/Inventory';
import Invoice from '../pages/Invoice';
import Login from '../pages/Login';
import NotFound from '../pages/NotFound';
import Register from '../pages/Register';
import ResetPassword from '../pages/ResetPassword';
import Vendor from '../pages/Vendor';

export default function Links() {
  return (
    <>
      <Routes>
        <Route path="/" element={
            <AuthenticatedRoute>
              <Home />
            </AuthenticatedRoute>
          } 
        />
        <Route path='/login' element={
            <UnauthenticatedRoute>
              <Login />
            </UnauthenticatedRoute>
          } 
        />
        <Route path="/login/reset" element={
            <UnauthenticatedRoute>
              <ResetPassword />
            </UnauthenticatedRoute>
          }
        />
        <Route path='/register' element={
            <UnauthenticatedRoute>
              <Register />
            </UnauthenticatedRoute>
          } 
        />
        <Route path="/inventory" element={
            <AuthenticatedRoute>
              <Inventory />
            </AuthenticatedRoute>
          } 
        />
        <Route path="/vendors" element={
            <AuthenticatedRoute>
              <Vendor />
            </AuthenticatedRoute>
          } 
        />
        <Route path="/invoices" element={
            <AuthenticatedRoute>
              <Invoice />
            </AuthenticatedRoute>
          } 
        />
        <Route path="/deliveries" element={
            <AuthenticatedRoute>
              <Delivery />
            </AuthenticatedRoute>
          } 
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}