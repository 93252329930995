import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

import { queryKeys } from '../../../utility/QueryKeys';

const createItem = async(item: any) => {
    const request = await API.post("api", "/items", {
        body: item,
      });
  
    return request;
    }

  export function useCreateItem() {
    const queryClient = useQueryClient();
    const nav = useNavigate();
    
    const { mutate } = useMutation([queryKeys.createItem], (item: any) => createItem(item), {
        onSuccess: () => {
            queryClient.invalidateQueries([queryKeys.items]);
            nav("/inventory")
        }
    });
  
    return mutate;
  }