import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { queryKeys } from '../../../utility/QueryKeys';

const loadProducts = async(vendorId: any) => {
  const response = await API.get("api", `/products/${vendorId}`, {});

  return response;
  }

export function useAllProductsByVendor(vendorId: any) {
  const fallback: any[] = [];

  const { data = fallback } = useQuery([queryKeys.products, vendorId], () => loadProducts(vendorId), {
    enabled: !!vendorId
  });

  return data;
}