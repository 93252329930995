import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';

import { queryKeys } from '../../../utility/QueryKeys';

const updateItem = async(newValue: number, id: any, columnId: any) => {
    const json = {[columnId]: newValue}
    const request = await API.put("api", `/items/${id}`, {
        body: json
    });
    return request;
}

export function useRemoveInventory(id: any, columnId: any){
    const queryClient = useQueryClient();

    const { mutate } = useMutation((newValue: number) => updateItem(newValue, id, columnId), {
        onSuccess: () => {
            queryClient.invalidateQueries([queryKeys.items]);
        },
    });

    return mutate;
}
