import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { queryKeys } from '../../../utility/QueryKeys';

const loadQueue = async(id: any) => {
    const response = await API.get("api", `/queues/${id}`, {});
  
    return response;
    }

  export function useQueue(id: any) {
    const fallback: any[] = [];

    const { data = fallback} = useQuery([id === 1 ? queryKeys.queue : queryKeys.takeQueue], () => loadQueue(id));
  
    return data;
  }