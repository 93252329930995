import { Button, Container, Heading, Input, Stack, Tab, TabList, TabPanel, TabPanels, Table, TableContainer, Tabs, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { AsyncSelect } from 'chakra-react-select';
import { useEffect, useState } from 'react';
import { useAllItems } from '../inventory/hooks/useAllItems';
import { useQueue } from './hooks/useQueue';
import { useRemoveInventory } from './hooks/useRemoveInventory';
import { useUpdateQueue } from './hooks/useUpdateQueue';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';

function Dashboard() {
  const queue = useQueue(1);
  const items = useAllItems();
  const [group, setGroup] = useState('');

  useEffect(() => {
    const checkUserGroup = async() => {
      await Auth.currentSession()
        .then((data) => {
            const groups = data.getIdToken().payload['cognito:groups'];
            setGroup(groups[0]);
        })
        .catch((err) => {
            console.log(err);
        });
    }
    
    checkUserGroup();
  }, [group]);

  
  const CreateQueueTable = () => {
    const updateQueue = useUpdateQueue(1);
    const queueData: any[] = queue.queueItems;
    const [selectLabel, setSelectLabel] = useState('');
    const [selectValue, setSelectValue] = useState('');
    const [quantityInput, setQuantityInput] = useState(0);

    const isFetching = useIsFetching();
    const isMutating = useIsMutating();

    const dequeue = () => {
      const newQueue = JSON.parse(JSON.stringify(queueData));

      newQueue.shift();

      return newQueue;
    }

    const options = items.map((item: any) => ({value: item.info, label: item.info}));

    const filterOptions = (inputValue: any) => {
      return options.filter((i: any) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    };

    const loadOptions = (inputValue: any, callback: any) => {
          callback(filterOptions(inputValue));
        };

    const createQueueItemObject = (item: string, quantity: number) => {
      const queueItem = {
        description: item,
        quantity: quantity,
      }

      //enqueue
      //create a deep copy of queueData and push the new queueItem to it
      const newQueue = JSON.parse(JSON.stringify(queueData));

      const filteredQueue = newQueue.filter((item: any) => item.description === queueItem.description).length;

      if(filteredQueue === 0){
        newQueue.push(queueItem);
        return newQueue;
      }

      else {
        //if the queue already contains the item, increment the quantity
        const index = newQueue.findIndex((item: any) => item.description === queueItem.description);
        newQueue[index].quantity += quantity;
        return newQueue;
      }
      
    }

    if(group === 'Production'){
      return (
        <>
        <Stack direction={['column','row']} spacing={5} mt={10}>
        <Container w={500} h={325} shadow={'lg'}>
        <Heading mt={2} mb={10}>
          Warehouse Items Queue
        </Heading>
        <Text>
          Search Items Here
        </Text>
            <AsyncSelect
              defaultOptions={true}
              loadOptions={loadOptions}
              defaultInputValue={selectLabel}
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.value}
              value={{value: selectValue, label: selectLabel} || null}
              onChange={
                  (value : any) => {
                    setSelectValue(value.value)
                    setSelectLabel(value.label)
                  } 
                }
              />
            <Input 
              mt={4}
              type={"number"}
              name={'quantityInput'}
              value={quantityInput || ''}
              placeholder={'Enter Order Quantity'}
              onChange={(e) => {setQuantityInput(parseInt(e.target.value))}}
              required={false}
            />
            <Stack mt={10} direction={'row'} alignItems={'center'} spacing={10} maxW={500}>
            <Button variant='solid' isDisabled={((isFetching || isMutating) ? true : false) || (quantityInput === 0) || (selectValue === '')} onClick={
                () => {
                  updateQueue(createQueueItemObject(selectValue, quantityInput));
                  setSelectValue('');
                  setSelectLabel('');
                  setQuantityInput(0);
                  }
                }
                  >
              Add to Queue
            </Button>
            </Stack>
            </Container>
            <TableContainer h={325} w={500} shadow={'lg'} overflowY={'scroll'}>
              <Table variant='simple' key={'prodQueueTable'}>
                <Thead>
                  <Tr key={"createQueueHeader"}>
                    <Th key={"item"}>Item</Th>
                    <Th key={"quantity"}>Quantity</Th>
                  </Tr>
                </Thead>
                <Tbody>
                {queueData?.map((item: any) => (
                  <Tr key={item.description}>
                    <Td key={item.description} minW={300}>{item.description}</Td>
                    <Td key={item.description+item.quantity}>{item.quantity}</Td>
                  </Tr>
                  ))
                }
                </Tbody>
              </Table>
            </TableContainer>
            </Stack>
            </>
        )
    }
    else {
      return (
        <>
        <Stack direction={['column','row']} spacing={5} mt={10}>
        <Container w={500} h={325} shadow={'lg'}>
        <Heading mt={2} mb={10}>
          Warehouse Items Queue
        </Heading>
        <Text>
          Search Items Here
        </Text>
            <AsyncSelect
              defaultOptions={true}
              loadOptions={loadOptions}
              defaultInputValue={selectLabel}
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.value}
              value={{value: selectValue, label: selectLabel} || null}
              onChange={
                  (value : any) => {
                    setSelectValue(value.value)
                    setSelectLabel(value.label)
                  } 
                }
              />
            <Input 
              mt={4}
              type={"number"}
              name={'quantityInput'}
              value={quantityInput || ''}
              placeholder={'Enter Order Quantity'}
              onChange={(e) => {setQuantityInput(parseInt(e.target.value))}}
              required={false}
            />
            <Stack mt={10} direction={'row'} alignItems={'center'} spacing={10} maxW={500}>
            <Button variant='solid' isDisabled={((isFetching || isMutating) ? true : false) || (quantityInput === 0) || (selectValue === '')} onClick={
                () => {
                  updateQueue(createQueueItemObject(selectValue, quantityInput));
                  setSelectValue('');
                  setSelectLabel('');
                  setQuantityInput(0);
                  }
                }
                  >
              Add to Queue
            </Button>
            <Button colorScheme={'red'} variant='solid' onClick={() => updateQueue(dequeue())} isDisabled={((isFetching || isMutating) ? true : false)}>
              Remove from Queue
            </Button>
            </Stack>
            </Container>
            <TableContainer h={325} w={500} shadow={'lg'} overflowY={'scroll'}>
              <Table variant='simple' key={'adminQueueTable'}>
                <Thead>
                  <Tr key={"createQueueHeader"}>
                    <Th key={"item"}>Item</Th>
                    <Th key={"quantity"}>Quantity</Th>
                  </Tr>
                </Thead>
                <Tbody>
                {queueData?.map((item: any) => (
                  <Tr key={item.description}>
                    <Td key={item.description} minW={300}>{item.description}</Td>
                    <Td key={item.description+item.quantity}>{item.quantity}</Td>
                  </Tr>
                  ))
                }
                </Tbody>
              </Table>
            </TableContainer>
            </Stack>
            </>
      )
    }
  }

  const CreateTakeTable = () => {
    const takeQueue = useQueue(2);
    const updateTakeQueue = useUpdateQueue(2);
    const takeQueueData: any[] = takeQueue.queueItems;
    const [selectTakeLabel, setSelectTakeLabel] = useState('');
    const [selectTakeValue, setSelectTakeValue] = useState({
      info: '',
      itemId: '',
      stock: 0
    });
    const [takeQuantityInput, setTakeQuantityInput] = useState(0);
    const [topItemId, setTopItemId] = useState('');
    const handleRemove = useRemoveInventory(topItemId, 'stock');

    const isFetching = useIsFetching();
    const isMutating = useIsMutating();

    const dequeue = () => {
      const newQueue = JSON.parse(JSON.stringify(takeQueueData));

      newQueue.shift();

      return newQueue;
    }

    const options = items.map((item: any) => ({value: {info: item.info, itemId: item.itemId, stock: item.stock}, label: item.info}));

    const filterOptions = (inputValue: any) => {
      return options.filter((i: any) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    };

    const loadOptions = (inputValue: any, callback: any) => {
          callback(filterOptions(inputValue));
        };

    const createQueueItemObject = (item: string, quantity: number, itemId: string, originalStock: number) => {
      const queueItem = {
        description: item,
        quantity: quantity,
        itemId: itemId,
        originalStock: +originalStock
      }

      //enqueue
      //create a deep copy of queueData and push the new queueItem to it
      const newQueue = JSON.parse(JSON.stringify(takeQueueData));

      const filteredQueue = newQueue.filter((item: any) => item.description === queueItem.description).length;

      if(filteredQueue === 0){
        newQueue.push(queueItem);
        return newQueue;
      }

      else {
        //if the queue already contains the item, increment the quantity
        const index = newQueue.findIndex((item: any) => item.description === queueItem.description);
        newQueue[index].quantity += quantity;
        return newQueue;
      }
      
    }

    if(group === 'Production'){
      return (
        <>
        <Stack direction={['column','row']} spacing={5} mt={10}>
        <Container w={500} h={325} shadow={'lg'}>
        <Heading mt={2} mb={10}>
          Take Warehouse Items List
        </Heading>
        <Text>
          Search Items Here
        </Text>
            <AsyncSelect
              defaultOptions={true}
              loadOptions={loadOptions}
              defaultInputValue={selectTakeLabel}
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.value}
              value={{value: selectTakeValue, label: selectTakeLabel} || null}
              onChange={
                  (value : any) => {
                    setSelectTakeValue(value.value)
                    setSelectTakeLabel(value.label)
                  } 
                }
              />
            <Input 
              mt={4}
              type={"number"}
              name={'quantityInput'}
              value={takeQuantityInput || ''}
              placeholder={'Enter Order Quantity'}
              onChange={(e) => {setTakeQuantityInput(parseInt(e.target.value))}}
              required={false}
            />
            <Stack mt={10} direction={'row'} alignItems={'center'} spacing={10} maxW={500}>
            <Button variant='solid' isDisabled={((isFetching || isMutating) ? true : false) || (takeQuantityInput === 0) || (selectTakeValue === undefined)} onClick={
                () => {
                  updateTakeQueue(createQueueItemObject(selectTakeLabel, takeQuantityInput, selectTakeValue.itemId, selectTakeValue.stock));
                  setSelectTakeValue({
                    info: '',
                    itemId: '',
                    stock: 0
                  });
                  setSelectTakeLabel('');
                  setTakeQuantityInput(0);
                  }
                }
                  >
              Take
            </Button>
            </Stack>
            </Container>
            <TableContainer h={325} w={500} shadow={'lg'} overflowY={'scroll'}>
              <Table variant='simple' key={"prodTakeTable"}>
                <Thead>
                  <Tr key={"createTakeQueueHeader"}>
                    <Th key={"takeItem"}>Item</Th>
                    <Th key={"takeQuantity"}>Quantity</Th>
                  </Tr>
                </Thead>
                <Tbody>
                {takeQueueData?.map((item: any) => (
                  <Tr key={item.description}>
                    <Td key={item.description} minW={300}>{item.description}</Td>
                    <Td key={item.description+item.quantity}>{item.quantity}</Td>
                  </Tr>
                  ))
                }
                </Tbody>
              </Table>
            </TableContainer>
            </Stack>
            </>
        )
    }
    else {
      return (
        <>
        <Stack direction={['column','row']} spacing={5} mt={10}>
        <Container w={500} h={325} shadow={'lg'}>
        <Heading mt={2} mb={10}>
        Take Warehouse Items List
        </Heading>
        <Text>
          Search Items Here
        </Text>
            <AsyncSelect
              defaultOptions={true}
              loadOptions={loadOptions}
              defaultInputValue={selectTakeLabel}
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.value}
              value={{value: selectTakeValue, label: selectTakeLabel} || null}
              onChange={
                  (value : any) => {
                    setSelectTakeValue(value.value)
                    setSelectTakeLabel(value.label)
                  } 
                }
              />
            <Input 
              mt={4}
              type={"number"}
              name={'quantityInput'}
              value={takeQuantityInput || ''}
              placeholder={'Enter Order Quantity'}
              onChange={(e) => {setTakeQuantityInput(parseInt(e.target.value))}}
              required={false}
            />
            <Stack mt={10} direction={'row'} alignItems={'center'} spacing={10} maxW={500}>
            <Button variant='solid' isDisabled={((isFetching || isMutating) ? true : false) || (takeQuantityInput === 0) || (selectTakeValue === undefined)} onClick={
                () => {
                  updateTakeQueue(createQueueItemObject(selectTakeLabel, takeQuantityInput, selectTakeValue.itemId, selectTakeValue.stock));
                  setSelectTakeValue({
                    info: '',
                    itemId: '',
                    stock: 0
                  });
                  setSelectTakeLabel('');
                  setTakeQuantityInput(0);
                  }
                }
                  >
              Take
            </Button>
            <Button colorScheme={'red'} variant='solid' onClick={() => 
              {
                //this version of dequeue will also remove the amount from inventory
                setTopItemId(takeQueueData[0].itemId);
                handleRemove(takeQueueData[0].originalStock - takeQueueData[0].quantity);
                updateTakeQueue(dequeue());
                }} 
                isDisabled={((isFetching || isMutating) ? true : false)}>
              Remove from Inventory
            </Button>
            </Stack>
            </Container>
            <TableContainer h={325} w={500} shadow={'lg'} overflowY={'scroll'}>
              <Table variant='simple' key={'adminTakeTable'}>
                <Thead>
                  <Tr key={"createTakeQueueHeader"}>
                    <Th key={"takeItem"}>Item</Th>
                    <Th key={"takeQuantity"}>Quantity</Th>
                  </Tr>
                </Thead>
                <Tbody>
                {takeQueueData?.map((item: any) => (
                  <Tr key={item.description}>
                    <Td key={item.description} minW={300}>{item.description}</Td>
                    <Td key={item.description+item.quantity}>{item.quantity}</Td>
                  </Tr>
                  ))
                }
                </Tbody>
              </Table>
            </TableContainer>
            </Stack>
            </>
      )
    }
  }

  return (
    <Tabs variant={'enclosed-colored'} isLazy>
    <TabList>
        <Tab id='queueTable'>Main</Tab>
        <Tab id='takeTable'>Take List</Tab>
    </TabList>

    <TabPanels>
        <TabPanel id='queueTable'>
          <CreateQueueTable key={'queueTable'}/>
        </TabPanel>
        <TabPanel id='takeTable'>
          <CreateTakeTable key={'takeTable'}/>
        </TabPanel>
    </TabPanels>
    </Tabs>
  )
}

export default Dashboard