import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { queryKeys } from '../../../utility/QueryKeys';

const loadDeliveries = async() => {
  const response = await API.get("api", "/deliveries", {});

  return response;
  }

export function useAllDeliveries() {
  const fallback: any[] = [];

  const { data = fallback } = useQuery([queryKeys.deliveries], loadDeliveries);

  return data;
}