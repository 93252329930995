import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead, Tr,
  chakra,
  useDisclosure
} from '@chakra-ui/react';
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import {
  ColumnDef,
  FilterFn,
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { AsyncSelect } from 'chakra-react-select';
import { HTMLProps, useEffect, useRef, useState } from 'react';
import { AiFillPrinter } from 'react-icons/ai';

import { useAllItems } from '../inventory/hooks/useAllItems';
import { useAllPoByVendor } from './hooks/useAllPoByVendor';
import { useAllProductsByVendor } from './hooks/useAllProductsByVendor';
import { useAllVendors } from './hooks/useAllVendors';
import { useCreateOrUpdatePo } from './hooks/useCreateOrUpdatePo';
import { useCreatePurchaseOrderDoc } from './hooks/useCreatePurchaseOrderDoc';
import { useDeleteProducts } from './hooks/useDeleteProducts';
import { useUpdateProduct } from './hooks/useUpdateProduct';
import { useUpdateProductList } from './hooks/useUpdateProductList';

declare module '@tanstack/table-core' {
    interface FilterFns {
      fuzzy: FilterFn<unknown>
    }
    interface FilterMeta {
      itemRank: RankingInfo
    }
  }
  
  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)
  
    // Store the itemRank info
    addMeta({
      itemRank,
    })
  
    // Return if the item should be filtered in/out
    return itemRank.passed
  }

  enum PriceType {
    'perUnit' = 'per Unit',
    'perPound' = 'per Pound',
  }
  
  export type Product = {
    productId: string;
    vendorId: string;
    itemId: string;
    brand: string;
    description: string;
    quantity: string;
    unitPrice: number;
    priceType: PriceType;
  }
  
  const defaultColumn: Partial<ColumnDef<Product>> = {
    cell: function Cell ({ getValue, column, row:{getValue: getRowValue}}) {
      const initialValue = getValue();
      const [value, setValue] = useState(initialValue);
      const [selectValue, setSelectValue] = useState('');
  
      useEffect(() => {
        setValue(initialValue)
      }, [initialValue])
  
      const productId = getRowValue('productId');
      const vendorId = getRowValue('vendorId');
      const columnId = column.id;
  
      const handleUpdate = useUpdateProduct(productId, columnId, vendorId);

      const isProductOrVendorId = (column.id === 'productId' || column.id === 'vendorId');

      const items = useAllItems();

      const options = items.map((item: any) => ({value: item.itemId, label: item.itemId + ": " + item.info}));

      const filterOptions = (inputValue: any) => {
        return options.filter((i: any) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
      };

      const loadOptions = (inputValue: any, callback: any) => {
            callback(filterOptions(inputValue));
          };

    if (column.id === 'priceType')

        return (
            <Select 
                placeholder='Select'
                onBlur={e => handleUpdate(e.target.value)}
                flex={'auto'}
                value={value as string}
                onChange={e => setValue(e.target.value)}>
            <option value={PriceType.perUnit}>Per Unit</option>
            <option value={PriceType.perPound}>Per Pound</option>
            </Select>
            )

    else if (column.id === 'itemId')
          
        return (
            <AsyncSelect
                defaultOptions={true}
                loadOptions={loadOptions}
                defaultInputValue={value as string}
                getOptionValue={(option: any) => option.value}
                value={{value: selectValue, label: selectValue} || null}
                onBlur={() => handleUpdate(selectValue)}
                onChange={(value : any) => setSelectValue(value.value)} />
            );

    else 
    
        return (
            <Input
                onBlur={!isProductOrVendorId ? (e => handleUpdate(e.target.value)) : undefined}
                readOnly={isProductOrVendorId}
                flex={'auto'}
                value={value as string}
                onChange={e => setValue(e.target.value)}/>
            )
    }
  }

function VendorCards() {
    const vendorData = useAllVendors();
    
    const createCards = () => {
        return (
            vendorData.map((vendor: any) => (
                <CardTemplate  key={vendor.vendorId} vendor={vendor}/>))
        )
    }

    const CardTemplate = ({vendor}: any) => {
        const productModal = useDisclosure();
        const poModal = useDisclosure();
        const createPoDrawer = useDisclosure();
        const firstField= useRef(null);
        const productData = useAllProductsByVendor(vendor.vendorId);
        const purchaseOrderData = useAllPoByVendor(vendor.vendorId);
        const [sorting, setSorting] = useState<SortingState>([]);
        const [rowSelection, setRowSelection] = useState({});
        const columnHelper = createColumnHelper<Product>();
        const [globalFilter, setGlobalFilter] = useState('');
        const [file, setFile] = useState();
        const [array, setArray] = useState([] as Product[]);
        const [selectValue, setSelectValue] = useState([] as any[]);
        const [selectLabel, setSelectLabel] = useState('');
        const [quantityInput, setQuantityInput] = useState(0);
        const [search, setSearch] = useState('');
        const date = new Date().toISOString().slice(0, 10);
        const [selectedPo, setSelectedPo] = useState('' as any);
        const [injectPo, setInjectPo] = useState(false);
        const [poFormData, setPoFormData] = useState({
          orderId: '',
          vendorId: vendor.vendorId,
          productList: [] as any[],
          orderDate: date,
          deliveryDate: '',
          totalQuantity: 0,
          totalPrice: 0
        });
      
        const {orderId, vendorId, productList, orderDate, deliveryDate, totalQuantity, totalPrice} = poFormData;
    
        useEffect(() => {
          onChange({target: {name: 'totalQuantity', value: calculateTotalQuantity(productList)}});
          onChange({target: {name: 'totalPrice', value: calculateTotalPrice(productList)}});
        }, [productList]);

        useEffect(() => {
          if(!createPoDrawer.isOpen) {
            setPoFormData({
              orderId: '',
              vendorId: vendor.vendorId,
              productList: [] as any[],
              orderDate: date,
              deliveryDate: '',
              totalQuantity: 0,
              totalPrice: 0
            });
          }
        }, [createPoDrawer.isOpen, date, vendor.vendorId]);

        useCreatePurchaseOrderDoc(selectedPo, vendor.name, injectPo);

        const handleSearch = (e: any) => {
          setSearch(e.target.value);
        }

        const onSubmit = useCreateOrUpdatePo(orderId);
    
        const onChange = (e: any) => {
            setPoFormData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }))
        }

        const calculateTotalQuantity = (arr: any[]) => {
          return arr.map((product: any) => product.orderQuantity).reduce((a: number, b: number) => a + b, 0);
          
        }

        const calculateTotalPrice = (arr: any[]) => {

          const totalPriceNumber = arr.map((product: any) => product.extendedPrice).reduce((a: number, b: number) => a + b, 0);
          return Number(totalPriceNumber.toFixed(2));
          
        } 

        const createProductObject = (arr: any[], orderQuantity: number) => {

          arr.push(orderQuantity);

          const extendedPrice = Number((arr[4] * arr[6]).toFixed(2));

          arr.push(extendedPrice);

          const productObject = {
            productId: arr[0],
            brand: arr[1],
            description: arr[2],
            quantity: arr[3],
            unitPrice: arr[4],
            priceType: arr[5],
            orderQuantity: arr[6],
            extendedPrice: arr[7]
          }
          
          const oldArr = [...productList];
          const newArr = oldArr.concat(productObject);
          onChange({target: {name: 'productList', value: newArr}});
        }

        const removeProduct = (arr: any[], id: string) => {
          const oldArr = [...arr];
          const newArr = oldArr.filter((product: any) => product.productId !== id);
          onChange({target: {name: 'productList', value: newArr}});
        }
    
        const handleSubmit = (e: any) => {
            e.preventDefault();
            onSubmit(poFormData);
            createPoDrawer.onClose();
        }
    
        const validateForm = () => {
            return orderDate.length > 0
            && deliveryDate.length > 0
          }

        const options = productData.map((product: any) => ({value: [product.productId, product.brand, product.description, product.quantity, product.unitPrice, product.priceType], label: product.productId + ": " + product.brand + " " + product.description}));

        const filterOptions = (inputValue: any) => {
          return options.filter((i: any) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
          );
        };
  
        const loadOptions = (inputValue: any, callback: any) => {
              callback(filterOptions(inputValue));
            };

        const isMutatingItem = useIsMutating();
        const isFetchingItem = useIsFetching();

        const handleUpdateList = useUpdateProductList();

        const fileReader = new FileReader();

        const csvFileToArray = (string: any) => {
            const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
            const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

            const array = csvRows.map((i: any) => {
                const values = i.split(",");
                const obj: Product = csvHeader.reduce((object: any, header: any, index: any) => {
                    object[header] = values[index];
                    if (index === 6) {
                        object[header] = Number(values[index]);
                    }
                    return object;
                }, {});
                return obj;
            });
        
            setArray(array);
        };

        const chunkArray = (array: Product[], size: any) => {
            const chunkedArray = [];
            let index = 0;
            while (index < array.length) {
                chunkedArray.push(array.slice(index, size + index));
                index += size;
            }
            return chunkedArray;
        };

        const sleep = (ms: any) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        };

        async function asyncForEach(array: Product[], callback: any) {
            for (let index = 0; index < array.length; index++) {
              await callback(array[index]);
              await sleep(1000);
            }
          }

        const updateEach = () => {
            console.log(array);

            const updateFunction = (array: Product[]) => asyncForEach(array, handleUpdateList);

            const forEachChunkedArraySync = async(array: Product[], size: number, delay: number, callback: any) => {
                const chunked_array = chunkArray(array, size);
                for (let i = 0; i < chunked_array.length; i++) {
                    await callback(chunked_array[i]);
                    await sleep(delay);
                }
            };
            
           forEachChunkedArraySync(array, 10, 2000, updateFunction);
        }

        const handleOnChange = (e: any) => {
            setFile(e.target.files[0]);
        };
    
        const handleOnSubmit = (e: any) => {
            e.preventDefault();
    
            if (file) {
                fileReader.onload = function (event: any) {
                    //convert to json and call hook here
                    const text = event.target.result;
                    csvFileToArray(text);

                    updateEach();
                };
                fileReader.readAsText(file);

            }
        };

        const columns = [
            {
              id: 'select',
              header: ({ table }: any) => (
                <IndeterminateCheckbox
                  {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                  }}
                />
              ),
              cell: ({ row }: any) => (
                <div className="px-1">
                  <IndeterminateCheckbox
                    {...{
                      checked: row.getIsSelected(),
                      indeterminate: row.getIsSomeSelected(),
                      onChange: row.getToggleSelectedHandler(),
                    }}
                  />
                </div>
              ),
            },
            columnHelper.accessor("productId", {
              header: "Product ID",
              footer: (info: { column: { id: any; }; }) => info.column.id
            }),
            columnHelper.accessor("vendorId", {
              header: "Vendor ID",
              footer: (info: { column: { id: any; }; }) => info.column.id
            }),
            columnHelper.accessor("itemId", {
                header: "Item ID",
                footer: (info: { column: { id: any; }; }) => info.column.id
            }),
            columnHelper.accessor("brand", {
                header: "Brand",
                footer: (info: { column: { id: any; }; }) => info.column.id
            }),
            columnHelper.accessor("description", {
                header: "Description",
                size: 1000,
                footer: (info: { column: { id: any; }; }) => info.column.id
            }),
            columnHelper.accessor("quantity", {
              header: "Quantity",
              meta: {
                isNumeric: true
              },
              footer: (info: { column: { id: any; }; }) => info.column.id
            }),
            columnHelper.accessor("unitPrice", {
                header: "Unit Price",
                meta: {
                  isNumeric: true
                },
                footer: (info: { column: { id: any; }; }) => info.column.id
            }),
            columnHelper.accessor("priceType", {
              header: "Price Type",
              footer: (info: { column: { id: any; }; }) => info.column.id
            })
          ];

        const table = useReactTable({
            data: productData,
            columns,
            filterFns: {
                fuzzy: fuzzyFilter,
            },
            defaultColumn,
            onGlobalFilterChange: setGlobalFilter,
            globalFilterFn: fuzzyFilter,
            onRowSelectionChange: setRowSelection,
            getCoreRowModel: getCoreRowModel(),
            onSortingChange: setSorting,
            getSortedRowModel: getSortedRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            state: {
            rowSelection,
            sorting,
            globalFilter,
            },
            debugTable: true,
        });

        const selectedRowIds = () => {
            const values = table.getSelectedRowModel().rows.map((product) => product.getValue("productId"));
           
             return values;
           }
         
        const handleDelete = useDeleteProducts(vendor.vendorId);
         
        const deleteEach = () => {
            return selectedRowIds().forEach((productId) => handleDelete(productId));
           }

        const columnWidth = (columnId: any) => {
          if(columnId === 'description')
            return 300;
          else if(columnId === 'brand')
            return 150;
          else if(columnId === 'priceType')
            return 175;
          else if(columnId === 'productId')
            return 150;
          else if(columnId === 'vendorId')
            return 175;
          else
            return 100;
        }

        const createPOCards = (data: any) => {
          const poData = data;
          const dataSortedByOrderDate = poData.sort((a: any, b: any) => 
            (a.orderDate > b.orderDate) ? 1 : ((b.orderDate > a.orderDate) ? -1 : 0));

          if(search !== '')
          return(
            dataSortedByOrderDate.filter((po: any) => po.orderId.includes(search)).map((po: any) => (
              <POCardTemplate key={po.orderId} po={po}/>))
          )  
          
          else
          return (
              dataSortedByOrderDate.map((po: any) => (
                  <POCardTemplate key={po.orderId} po={po}/>))
          )
        }

        const POCardTemplate = ({po}: any) => {
          const lineItemsDrawer = useDisclosure();

          const toggleSelectedPo = (po: any) => {
            if(selectedPo === po){
              setSelectedPo('');
              setInjectPo(false);
            }
            else
              setSelectedPo(po);
              setInjectPo(false);
          }
          
          const fillEditPoData = (data: any) => {
            onChange({target: {name: 'orderId', value: data.orderId}});
            onChange({target: {name: 'vendorId', value: vendor.vendorId}});
            onChange({target: {name: 'orderDate', value: data.orderDate}});
            onChange({target: {name: 'deliveryDate', value: data.deliveryDate}});
            onChange({target: {name: 'productList', value: data.productList}});
            onChange({target: {name: 'totalQuantity', value: data.totalQuantity}});
            onChange({target: {name: 'totalPrice', value: data.totalPrice}});

            createPoDrawer.onOpen();
          }

          return(
            <Card
                direction={{ base: 'column', sm: 'row' }}
                overflow='hidden'
                variant={selectedPo === po ? 'filled' : 'outline'}
                maxW={300}
                maxH={190}
                onClick={() => toggleSelectedPo(po)} 
                >
                <Stack>
                    <CardBody height={24}>
                    <Heading size='md'>{po.orderId}</Heading>
                    <Text py='2' lineHeight={6}>
                        Order Date: {po.orderDate}
                        <br />
                        Delivery Date: {po.deliveryDate}
                        <br />
                    </Text>
                    </CardBody>
                    <CardFooter>
                      <Button variant='solid' colorScheme='blue' onClick={(event) => {
                        lineItemsDrawer.onOpen()
                        event.stopPropagation();
                      }} 
                        isDisabled={((isFetchingItem || isMutatingItem) ? true : false) || (po.productList === null)}>
                          View Line Items
                      </Button>
                      <Button 
                        ml={3} 
                        variant='solid' 
                        colorScheme='teal' 
                        onClick={() => fillEditPoData(po)} 
                        isDisabled={((isFetchingItem || isMutatingItem) ? true : false) || (po.productList === null)}
                        >
                          Edit
                      </Button>
                      <Drawer
                        isOpen={lineItemsDrawer.isOpen}
                        placement='right'
                        onClose={lineItemsDrawer.onClose}
                        size='full'
                      >
                        <DrawerOverlay />
                        <DrawerContent>
                          <DrawerCloseButton />
                          <DrawerHeader>{po.orderId} Line Items</DrawerHeader>

                          <DrawerBody>
                          <TableContainer>
                            <Table variant='simple'>
                              <Thead>
                                <Tr key={"header"}>
                                  <Th key={"productId"}>Product ID</Th>
                                  <Th key={"brand"}>Brand</Th>
                                  <Th key={"description"}>Description</Th>
                                  <Th key={"quantity"} isNumeric>Quantity</Th>
                                  <Th key={"unitPrice"} isNumeric>Unit Price</Th>
                                  <Th key={"priceType"}>Price Type</Th>
                                  <Th key={"orderQuantity"} isNumeric>Order Quantity</Th>
                                  <Th key={"extendedPrice"} isNumeric>Extended Price</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                              {po.productList?.map((lineItem: any) => (
                                <Tr key={lineItem.productId}>
                                  <Td key={lineItem.productId}>{lineItem.productId}</Td>
                                  <Td key={lineItem.productId+lineItem.brand}>{lineItem.brand}</Td>
                                  <Td key={lineItem.productId+lineItem.description}>{lineItem.description}</Td>
                                  <Td key={lineItem.productId+lineItem.quantity}>{lineItem.quantity}</Td>
                                  <Td key={lineItem.productId+lineItem.unitPrice}>{lineItem.unitPrice}</Td>
                                  <Td key={lineItem.productId+lineItem.priceType}>{lineItem.priceType}</Td>
                                  <Td key={lineItem.productId+lineItem.orderQuantity}>{lineItem.orderQuantity}</Td>
                                  <Td key={lineItem.productId+lineItem.extendedPrice}>{lineItem.extendedPrice}</Td>
                                </Tr>
                                ))
                              }
                              </Tbody>
                            </Table>
                          </TableContainer>
                          </DrawerBody>

                          <DrawerFooter>
                            <Button colorScheme='blue' onClick={lineItemsDrawer.onClose}>Ok</Button>
                          </DrawerFooter>
                        </DrawerContent>
                      </Drawer>
                    </CardFooter>
                </Stack>
            </Card>
          )
        }

        return(
            <>
            <Card
                direction={{ base: 'column', sm: 'row' }}
                overflow='hidden'
                variant='outline'
                maxW={300}
                maxH={190}
                >
                <Stack>
                    <CardBody height={24}>
                    <Heading size='md'>{vendor.name}</Heading>
                    <Text py='2' lineHeight={6}>
                        Vendor ID: {vendor.vendorId}
                        <br />
                        Vendor Contact: {vendor.contact}
                        <br />
                    </Text>
                    </CardBody>
                    <CardFooter>
                    <Button variant='solid' colorScheme='blue' onClick={productModal.onOpen} isDisabled={(isFetchingItem || isMutatingItem) ? true : false}>
                        View Products
                    </Button>
                    <Button ml={10} variant='solid' onClick={poModal.onOpen} isDisabled={(isFetchingItem || isMutatingItem) ? true : false}>
                        PO
                    </Button>
                    <Modal size={'full'} isOpen={poModal.isOpen} onClose={poModal.onClose} scrollBehavior={'inside'}>
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader> 
                          {vendor.name} Purchase Orders
                          <Input
                            type="search"
                            name='poSearch'
                            value={search}
                            onChange={handleSearch}
                            placeholder='Search ID'
                            ml={4}
                            maxW={200}
                          />
                          <IconButton 
                            ml={3} 
                            aria-label="Print PDF"
                            icon={<AiFillPrinter />}
                            onClick={() => setInjectPo(true)}
                            isDisabled={((isFetchingItem || isMutatingItem) ? true : false) || (selectedPo === '')}
                          />
                          <Text fontSize={10}>
                            {selectedPo === '' ? 'No PO selected' : 'Selected PO: ' + selectedPo.orderId }
                          </Text>
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                        <Container maxW={'100%'} mt={4} maxH={'100%'} mb= {4}>
                            <SimpleGrid spacingY={4} columns={3}>
                                {createPOCards(purchaseOrderData)}
                            </SimpleGrid>
                        </Container>
                        <Drawer
                          size={'xl'}
                          isOpen={createPoDrawer.isOpen}
                          placement='right'
                          onClose={createPoDrawer.onClose}
                          initialFocusRef={firstField}
                          closeOnEsc={false}
                          closeOnOverlayClick={false}
                          > 
                          <DrawerOverlay />
                          <DrawerContent>
                            <DrawerHeader>Create PO</DrawerHeader>
                            <DrawerBody>
                            <form onSubmit={handleSubmit} id={"createPoForm"}>
                              <Stack spacing={4}>
                                  <FormControl id="orderId">
                                      <FormLabel>Order ID</FormLabel>
                                      <Input 
                                      type="text"
                                      name='orderId' 
                                      value={orderId}
                                      variant="filled"
                                      placeholder='Auto-Generated' 
                                      isReadOnly/>
                                  </FormControl>
                                  <FormControl id="vendorId">
                                      <FormLabel>Vendor ID</FormLabel>
                                      <Input 
                                      type="text"
                                      name='vendorId' 
                                      value={vendorId}
                                      variant="filled"
                                      placeholder='Vendor ID' 
                                      isReadOnly/>
                                  </FormControl>
                                  <FormControl id="orderDate" isRequired>
                                  <FormLabel>Order Date</FormLabel>
                                    <Input
                                      ref={firstField}
                                      type="date"
                                      name='orderDate'
                                      value={orderDate}
                                      onChange={onChange}
                                      placeholder='Select a date'
                                      />
                                  </FormControl>
                                  <FormControl id="deliveryDate" isRequired>
                                  <FormLabel>Delivery Date</FormLabel>
                                    <Input
                                      type={'date'}
                                      name='deliveryDate'
                                      value={deliveryDate}
                                      onChange={onChange}
                                      placeholder='Select a date'
                                      />
                                  </FormControl>
                                  <FormControl id='productList' isRequired>
                                  <FormLabel>Product List</FormLabel>
                                    <Input
                                    type={'hidden'}
                                    name='productList'
                                    value={productList}
                                    />
                                    <>
                                      <AsyncSelect 
                                      defaultOptions={true}
                                      loadOptions={loadOptions}
                                      defaultInputValue={selectLabel}
                                      getOptionLabel={(option: any) => option.label}
                                      getOptionValue={(option: any) => option.value}
                                      value={{value: selectValue, label: selectLabel} || null}
                                      onChange={
                                          (value : any) => {
                                            setSelectValue(value.value)
                                            setSelectLabel(value.label)
                                          } 
                                        }
                                      />
                                      <Input 
                                        mt={4}
                                        type={"number"}
                                        name={'quantityInput'}
                                        value={quantityInput || ''}
                                        placeholder={'Enter Order Quantity'}
                                        onChange={(e) => {setQuantityInput(parseInt(e.target.value))}}
                                        required={false}
                                      />
                                      <Button mt={4} variant='solid' onClick={
                                          () => {
                                            createProductObject(selectValue, quantityInput)
                                            setSelectLabel('');
                                            setQuantityInput(0);
                                            }
                                          }
                                            >
                                        Add 
                                      </Button>
                                      <TableContainer>
                                        <Table variant='simple'>
                                          <Thead>
                                            <Tr key={"createPoHeader"}>
                                              <Th key={"productId"}>Product ID</Th>
                                              <Th key={"brand"}>Brand</Th>
                                              <Th key={"description"}>Description</Th>
                                              <Th key={"quantity"} isNumeric>Quantity</Th>
                                              <Th key={"unitPrice"} isNumeric>Unit Price</Th>
                                              <Th key={"priceType"}>Price Type</Th>
                                              <Th key={"orderQuantity"} isNumeric>Order Quantity</Th>
                                              <Th key={"extendedPrice"} isNumeric>Extended Price</Th>
                                              <Th key={"remove"}>Remove</Th>
                                            </Tr>
                                          </Thead>
                                          <Tbody>
                                          {productList?.map((lineItem: any) => (
                                            <Tr key={lineItem.productId}>
                                              <Td key={lineItem.productId}>{lineItem.productId}</Td>
                                              <Td key={lineItem.productId+lineItem.brand}>{lineItem.brand}</Td>
                                              <Td key={lineItem.productId+lineItem.description}>{lineItem.description}</Td>
                                              <Td key={lineItem.productId+lineItem.quantity}>{lineItem.quantity}</Td>
                                              <Td key={lineItem.productId+lineItem.unitPrice}>{lineItem.unitPrice}</Td>
                                              <Td key={lineItem.productId+lineItem.priceType}>{lineItem.priceType}</Td>
                                              <Td key={lineItem.productId+lineItem.orderQuantity}>{lineItem.orderQuantity}</Td>
                                              <Td key={lineItem.productId+lineItem.extendedPrice}>{lineItem.extendedPrice}</Td>
                                              <Td>
                                                <Button variant='solid' colorScheme='red' onClick={() => {removeProduct(productList, lineItem.productId)}}>
                                                  -
                                                </Button>
                                              </Td>
                                            </Tr>
                                            ))
                                          }
                                          </Tbody>
                                        </Table>
                                      </TableContainer>
                                    </>
                                  </FormControl>
                                  <FormControl id="totalQuantity" isRequired>
                                      <FormLabel>Total Quantity</FormLabel>
                                      <Input 
                                      type="number"
                                      name='totalQuantity'
                                      value={totalQuantity} 
                                      isReadOnly />
                                  </FormControl>
                                  <FormControl id="totalPrice" isRequired>
                                      <FormLabel>Total Price</FormLabel>
                                      <Input 
                                      type="number"
                                      name='totalPrice' 
                                      value={totalPrice}
                                      isReadOnly />
                                  </FormControl>
                              </Stack>
                            </form>
                            </DrawerBody>
                            <DrawerFooter>
                              <Button variant='outline' mr={3} onClick={createPoDrawer.onClose} isDisabled={isFetchingItem || isMutatingItem ? true : false}>
                                Cancel
                              </Button>
                              <Button 
                                colorScheme='blue' 
                                type="submit"
                                form="createPoForm"
                                isDisabled={!validateForm() || (isFetchingItem || isMutatingItem) ? true : false}
                              >
                                Save
                              </Button>
                            </DrawerFooter>
                          </DrawerContent>
                        </Drawer>
                        </ModalBody>

                        <ModalFooter>
                          <Button variant='ghost' onClick={createPoDrawer.onOpen}>Create PO</Button>
                          <Button colorScheme='blue' ml={3} onClick={poModal.onClose}>
                            Close
                          </Button>
                        </ModalFooter>
                      </ModalContent>
                    </Modal>
                    </CardFooter>
                </Stack>
            </Card>
            <Modal isOpen={productModal.isOpen} onClose={productModal.onClose} size={'full'}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader mb={4}>Product View
                <Box boxShadow={'md'} position={'fixed'}>
                  <DebouncedInput
                      value={globalFilter ?? ''}
                      onChange={value => setGlobalFilter(String(value))}
                      placeholder="Search all columns..."
                  />
                </Box>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <TableContainer maxW={'100%'} maxH={400} overflowY={'scroll'}>
                    <Table variant='striped' colorScheme='teal'>
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                            const meta: any = header.column.columnDef.meta;
                            return (
                                <Th
                                colSpan={header.colSpan}
                                key={header.id}
                                onClick={header.column.getToggleSortingHandler()}
                                isNumeric={meta?.isNumeric}
                                >
                                {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                )}
                                <chakra.span pl={'4'}>
                                    {header.column.getIsSorted() ? (
                                    header.column.getIsSorted() === "desc" ? (
                                        <TriangleDownIcon aria-label="sorted descending" />
                                    ) : (
                                        <TriangleUpIcon aria-label="sorted ascending" />
                                    )
                                    ) : null}
                                </chakra.span>
                                </Th>
                            );
                            })}
                        </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                    {table.getRowModel().rows.map(row => {
                        return(
                        <Tr key={row.id}>
                            {row.getVisibleCells().map(cell => {
                            const meta: any = cell.column.columnDef.meta;
                            return (
                                <Td
                                minWidth={columnWidth(cell.column.id)}
                                key={cell.id}
                                isNumeric={meta?.isNumeric} >
                                {flexRender(
                                    cell.column.columnDef.cell, 
                                    cell.getContext()
                                    )}
                                </Td>
                                )
                            })}
                        </Tr>
                        )
                    })}
                    </Tbody>
                    </Table>
                </TableContainer>
                </ModalBody>
    
              <ModalFooter>
              <Button 
                    mr={400}
                    colorScheme='red'
                    justifyContent={'left'}
                    variant='outline'
                    onClick={deleteEach}>
                Delete Product(s)
                </Button>
                <input
                    type={"file"} 
                    accept={".csv"}
                    onChange={handleOnChange} />
                <Button
                    mr={10} 
                    colorScheme='blue'
                    variant='outline'
                    onClick={handleOnSubmit}
                    isDisabled={(isFetchingItem || isMutatingItem) ? true : false}>
                Update List
                </Button>
                <Button colorScheme='blue' mr={3} onClick={productModal.onClose}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          </>
        )
    }
    
  return (
    <Container maxW={'100%'} mt={4}  mb={4} maxH={'100%'}>
        <SimpleGrid spacingY={4} columns={[1,3]}>
            {createCards()}
        </SimpleGrid>
    </Container>
  )
}

function IndeterminateCheckbox({
    indeterminate,
    className = '',
    ...rest
  }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    const ref = useRef<HTMLInputElement>(null!)
  
    useEffect(() => {
      if (typeof indeterminate === 'boolean') {
        ref.current.indeterminate = !rest.checked && indeterminate
      }
    }, [ref, indeterminate, rest.checked])
  
    return (
      <input
        type="checkbox"
        ref={ref}
        className={className + ' cursor-pointer'}
        {...rest}
      />
    )
  }
  
  function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
  }: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
  } & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = useState(initialValue)
  
    useEffect(() => {
      setValue(initialValue)
    }, [initialValue])
  
    useEffect(() => {
      const timeout = setTimeout(() => {
        onChange(value)
      }, debounce)
  
      return () => clearTimeout(timeout)
    }, [debounce, onChange, value])
  
    return (
      <input {...props} value={value} onChange={e => setValue(e.target.value)} />
    )
  }
  

export default VendorCards