import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';

import { queryKeys } from '../../../utility/QueryKeys';

const updateItem = async(updatedQueue: any, id: any) => {
    const json = {queueItems: updatedQueue}
    const request = await API.put("api", `/queues/${id}`, {
        body: json
    });
    return request;
}

export function useUpdateQueue(id: any){
    const queryClient = useQueryClient();

    const { mutate } = useMutation((updatedQueue: any) => updateItem(updatedQueue, id), {
        onSuccess: () => {
            queryClient.invalidateQueries([id === 1 ? queryKeys.queue : queryKeys.takeQueue]);
        },
    });

    return mutate;
}
