import { createStandaloneToast} from '@chakra-ui/react';
import config from '../config';
import * as Sentry from "@sentry/react";

interface ErrorInfo {
  url?: string;
  [key: string]: any;
}

const isLocal = process.env.NODE_ENV === "development";

export function initSentry() {
  if (isLocal) {
    return;
  }

  Sentry.init({ dsn: config.SENTRY_DSN });
}

export function logError(error: any, errorInfo: ErrorInfo | null = null) {
  if (isLocal) {
    return;
  }

  Sentry.withScope((scope) => {
    errorInfo && scope.setExtras(errorInfo);
    Sentry.captureException(error);
  });
}

function onError(error: Error | any): void {

  const { toast } = createStandaloneToast();

  let errorInfo: ErrorInfo = {};
  let message = error.toString();

  // Auth errors
  if (!(error instanceof Error) && error.message) {
    errorInfo = error;
    message = error.message;
    error = new Error(message);
    // API errors
  } else if (error.config && error.config.url) {
    errorInfo.url = error.config.url;
  }

  logError(error, errorInfo);

  toast({
    title: message,
    status: 'error',
    variant: 'subtle',
    duration: 9000,
    isClosable: true,
  })
}

export default onError