import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API } from "aws-amplify";
import { queryKeys } from "../../../utility/QueryKeys";

const deleteItems = async(id: any) => {
    const request = await API.del("api", `/items/${id}`, {});

    return request;
}

export function useDeleteItems () {
    const queryClient = useQueryClient();

    const { mutate } = useMutation([queryKeys.deleteItem], (id: any) => deleteItems(id), {
        onSuccess: () => {
            queryClient.invalidateQueries([queryKeys.items]);
        },
    });

    return mutate;
}